<template lang="pug">
ui-dialog(
  modal
  closable
  :width="width"
  :visible-prop="show"
  @content:hide="hide"
)
  template(#header)
    slot(name="header")
  template(#content)
    .pop-confirm__body
      slot(name="content")
    .error-message(v-if="$slots.error")
      slot(name="error")
  template(#actions)
    ui-button(type="secondary" @click="cancel") Отменить
    ui-button.delete-button(:loader="loader" @click="$emit('delete')") Удалить
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UiButton from "@/components/ui/button/UiButton.vue";
import UiDialog from "@/components/ui/dialog/UiDialog.vue";

export default defineComponent({
  name: "ConfirmDelete",
  components: {
    UiButton,
    UiDialog,
  },
  emits: [
    'update:show',
    'delete',
    'cancel',
  ],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '480px',
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    function hide() {
      context.emit('update:show', false)
    }

    function cancel() {
      context.emit('cancel')
      context.emit('update:show', false)
    }

    return {
      hide,
      cancel,
    };
  },
});
</script>

<style scoped lang="scss">
@import 'assets/styles/mixin/fonts';
@import 'assets/styles/elements/buttons';

.pop-confirm__body {
  font-size: 13px;
  line-height: 17px;
}

.error-message {
  @include label-13-18;
  color: var(--main-red-color);
}
</style>
